exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cgu-js": () => import("./../../../src/pages/cgu.js" /* webpackChunkName: "component---src-pages-cgu-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-privacy-policy-3-js": () => import("./../../../src/pages/privacy-policy-3.js" /* webpackChunkName: "component---src-pages-privacy-policy-3-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-releasenotes-js": () => import("./../../../src/pages/releasenotes.js" /* webpackChunkName: "component---src-pages-releasenotes-js" */),
  "component---src-pages-term-and-conditions-of-use-js": () => import("./../../../src/pages/term-and-conditions-of-use.js" /* webpackChunkName: "component---src-pages-term-and-conditions-of-use-js" */)
}

